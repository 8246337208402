import React, { createContext, useContext } from 'react';
import firebase from 'firebase/app';
import getConfig from 'next/config';
import 'firebase/analytics';
import { getFirebaseConfig } from '../utils';
const { publicRuntimeConfig } = getConfig();
const firebaseConfig = getFirebaseConfig(publicRuntimeConfig.env);

const FirebaseAppContext = createContext(undefined);

// Check that `window` is in scope for the analytics module!
if (typeof window !== 'undefined' && !firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
	// To enable analytics. https://firebase.google.com/docs/analytics/get-started
	if ('measurementId' in firebaseConfig) firebase.analytics();
}

function FirebaseAppProvider({ children }) {
	return <FirebaseAppContext.Provider value={firebase}>{children}</FirebaseAppContext.Provider>;
}
function useFirebaseApp() {
	const fireApp = useContext(FirebaseAppContext);
	if (!fireApp) {
		throw new Error(
			'Cannot call useFirebaseApp unless your component is within a FirebaseAppProvider'
		);
	}
	return fireApp;
}

export { FirebaseAppProvider, useFirebaseApp, firebase };

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Returns a boolean based on DOM window object's present
 * @returns {boolean}
 */
const isClient = typeof window !== 'undefined';

/**
 * Returns a encoded search string for the query parameter
 * @param str
 * @returns {string}
 */
function encodedSearchString(str) {
	return encodeURIComponent(str.replace(/ /g, '+'));
}

/**
 * Checks if element is within the current viewport
 * @param el
 * @returns {boolean}
 */
function isInViewport(el) {
	if (isClient) {
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Simple function to get query string parameters from url.
 * @param name
 * @param url
 * @param after
 * @returns {string|null}
 */
function getParameterByName(name, url, after) {
	if (!url) url = !!(typeof window !== 'undefined') && window.location.href;
	name = name.replace(/[[\]]/g, '\\$&');
	let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	if (after) {
		return decodeURIComponent(results[2]).replace(/\+/g, ' ');
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Add a URL parameter (or modify if already exists)
 * @param uri
 * @param key
 * @param value
 * @returns {string}
 */
function updateUrlParameter(uri, key, value) {
	// remove the hash part before operating on the uri
	let i = uri.indexOf('#');
	let hash = i === -1 ? '' : uri.substr(i);
	uri = i === -1 ? uri : uri.substr(0, i);

	let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
	let separator = uri.indexOf('?') !== -1 ? '&' : '?';
	if (uri.match(re)) {
		uri = uri.replace(re, '$1' + key + '=' + value + '$2');
	} else {
		uri = uri + separator + key + '=' + value;
	}
	return uri + hash; // finally append the hash as well
}

/**
 * Returns current environment Firebase config object
 * @returns {{storageBucket: string, apiKey: string, messagingSenderId: string, appId: string, projectId: string, measurementId: string, databaseURL: string, authDomain: string}}
 */
function getFirebaseConfig(env) {
	const {
		FIREBASE_APIKEY,
		FIREBASE_AUTHDOMAIN,
		FIREBASE_DATABASE_URL,
		FIREBASE_PROJECT_ID,
		FIREBASE_STORAGE_BUCKET,
		FIREBASE_MESSAGING_SENDER_ID,
		FIREBASE_APP_ID,
		FIREBASE_MEASSUREMENT_ID,
	} = env;
	return {
		apiKey: FIREBASE_APIKEY,
		authDomain: FIREBASE_AUTHDOMAIN,
		databaseURL: FIREBASE_DATABASE_URL,
		projectId: FIREBASE_PROJECT_ID,
		storageBucket: FIREBASE_STORAGE_BUCKET,
		messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		appId: FIREBASE_APP_ID,
		measurementId: FIREBASE_MEASSUREMENT_ID,
	};
}

export {
	numberWithCommas,
	isClient,
	encodedSearchString,
	isInViewport,
	isObject,
	getParameterByName,
	updateUrlParameter,
	getFirebaseConfig,
};

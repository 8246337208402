import React, { useState, useEffect, useContext, createContext } from 'react';
import queryString from 'query-string';
import { firebase } from './firebase';
import 'firebase/auth';

const authContext = createContext(undefined);

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.addScope('email');
googleAuthProvider.addScope('profile');

const githubAuthProvider = new firebase.auth.GithubAuthProvider();
githubAuthProvider.addScope('user');

export function AuthProvider({ children }) {
	const auth = useAuthProvider();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
	return useContext(authContext);
};

function useAuthProvider() {
	const [user, setUser] = useState(undefined);
	const logIn = (email, password) => {
		return firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((response) => {
				setUser(response.user);
				return response.user;
			});
	};
	const logInWithGoogle = () => {
		return firebase
			.auth()
			.signInWithPopup(googleAuthProvider)
			.then((response) => {
				setUser(response.user);
				return response.user;
			});
	};
	const logInWithGithub = () => {
		return firebase
			.auth()
			.signInWithPopup(githubAuthProvider)
			.then((response) => {
				setUser(response.user);
				return response.user;
			});
	};
	const logInAnonymously = () => {
		return firebase
			.auth()
			.signInAnonymously()
			.then((response) => {
				setUser(response.user);
				return response.user;
			});
	};

	const signUp = (email, password) => {
		return firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)
			.then((response) => {
				setUser(response.user);
				return response.user;
			});
	};

	const updateProfile = (fullName) => {
		return firebase
			.auth()
			.currentUser.updateProfile({
				displayName: fullName,
			})
			.then((response) => response);
	};

	const signOut = () => {
		return firebase
			.auth()
			.signOut()
			.then(() => {
				setUser(undefined);
			});
	};

	const sendPasswordResetEmail = (email) => {
		return firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(() => {
				return true;
			});
	};

	const confirmPasswordReset = (password, code) => {
		const resetCode = code || getFromQueryString('oobCode');

		return firebase
			.auth()
			.confirmPasswordReset(resetCode, password)
			.then(() => {
				return true;
			});
	};

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged((userData) => {
			if (userData) {
				setUser(userData);
			} else {
				setUser(undefined);
			}
		});
		return () => unsubscribe();
	}, [user]);

	return {
		userId: user && user.uid,
		user,
		logIn,
		logInWithGoogle,
		logInWithGithub,
		updateProfile,
		logInAnonymously,
		signUp,
		signOut,
		sendPasswordResetEmail,
		confirmPasswordReset,
	};
}

const getFromQueryString = (key) => {
	return queryString.parse(window.location.search)[key];
};
